<template>
  <div class="tab-content">
    <div role="tabpanel" class="tab-pane active" id="daily">
      <div class="progress" id="month-progress">
        <div
          class="progress-bar active"
          id="month-progress-bar"
          style="width: 0%"
        ></div>
      </div>
      <div class="container text-secondary mb-3">
        <div class="row">
          <div class="col-12">
            <strong>Steigbügel:</strong>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <ul class="emoji-list">
              <li>Was hab ich gestern erreicht? Was war spannend?</li>
              <li>Wo häng ich fest? Wer kann mich unterstützen?</li>
            </ul>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <ul class="emoji-list">
              <li>
                Welche Nüsse knack ich heute? Wo kann ich Pairing gebrauchen?
              </li>
              <li><i>Hab ich die Woche vor im Büro zu arbeiten?</i></li>
            </ul>
          </div>
        </div>
      </div>
      <div id="team-reports">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12 team-panels">
            <div class="col-lg-12 member" id="Daniel Schwartz">
              <img src="images/daniel_2_small.png" class="member-avatar" />
              <h2 class="member-name">Daniel</h2>
              <p class="date"></p>
              <hr />
              <div>found no worklogs in this month :(</div>
            </div>
            <div class="col-lg-12 member" id="David Schmiech">
              <img src="images/dave_2_small.png" class="member-avatar" />
              <h2 class="member-name">Dave</h2>
              <p class="date"></p>
              <hr />
              <div>found no worklogs in this month :(</div>
            </div>
            <div class="col-lg-12 member" id="Christoph Kurpat">
              <img src="images/christoph_2_small.png" class="member-avatar" />
              <h2 class="member-name">Christoph</h2>
              <p class="date"></p>
              <hr />
              <div>found no worklogs in this month :(</div>
            </div>
            <div class="col-lg-12 member" id="Lukas Meck">
              <img src="images/lukas_2_small.png" class="member-avatar" />
              <h2 class="member-name">Lukas</h2>
              <p class="date"></p>
              <hr />
              <div>found no worklogs in this month :(</div>
            </div>
            <div class="col-lg-12 member" id="Christopher Schwartz">
              <img src="images/chrissi_2_small.png" class="member-avatar" />
              <h2 class="member-name">Chrissi</h2>
              <p class="date"></p>
              <hr />
              <div>found no worklogs in this month :(</div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 team-panels">
            <div class="col-lg-12 member" id="Stefan">
              <img src="images/stefan_2_small.png" class="member-avatar" />
              <h2 class="member-name">Stefan</h2>
              <p class="date"></p>
              <hr />
              <div>found no worklogs in this month :(</div>
            </div>
            <div class="col-lg-12 member" id="Michal Jacko">
              <img src="images/michal_2_small.png" class="member-avatar" />
              <h2 class="member-name">Michal</h2>
              <p class="date"></p>
              <hr />
              <div>found no worklogs in this month :(</div>
            </div>
            <div class="col-lg-12 member" id="Moritz Grand">
              <img src="images/moritz_2_small.png" class="member-avatar" />
              <h2 class="member-name">Moritz</h2>
              <p class="date"></p>
              <hr />
              <div>found no worklogs in this month :(</div>
            </div>
            <div class="col-lg-12 member" id="susanna lankocz">
              <img src="images/susanna_2_small.png" class="member-avatar" />
              <h2 class="member-name">Susanna</h2>
              <p class="date"></p>
              <hr />
              <div>found no worklogs in this month :(</div>
            </div>
            <div class="col-lg-12 member" id="Hien Thu Nguyen">
              <img src="images/hien2_small.png" class="member-avatar" />
              <h2 class="member-name">Hiền</h2>
              <p class="date"></p>
              <hr />
              <div>found no worklogs in this month :(</div>
            </div>
          </div>
        </div>
        <img
          class="mx-auto"
          src="images/ellipsis.gif"
          id="team-reports-loading-animation"
          style="visibility: visible"
        />
      </div>
      <div class="row">
        <div
          class="col-xl-4 col-md-4 col-12 panel"
          id="totaltime-preview"
        ></div>
        <div
          class="col-xl-4 col-md-4 col-12 panel"
          id="logima-overview-preview"
        ></div>
        <div
          class="col-xl-4 col-md-4 col-12 panel"
          id="internal-tasks-preview"
        ></div>
        <div
          class="col-xl-4 col-md-4 col-12 panel"
          id="radcal-accounts-preview"
        ></div>
        <div
          class="col-xl-4 col-md-4 col-12 panel"
          id="vacutec-accounts-preview"
        ></div>
        <div
          class="col-xl-4 col-md-4 col-12 panel"
          id="iba-overview-preview"
        ></div>
      </div>
      <div>
        <BudgetBar name="Radcal" />
        <BudgetBar name="VacuTec" />
        <BudgetBar name="Thermo" />
        <BudgetBar name="Iba" />
        <BudgetBar name="Vorsorge" />
      </div>
    </div>
  </div>
</template>

<script>
import Tab from "../../../public/js/DailyTab.js";
import BudgetBar from "./BudgetBar";
export default {
  mounted() {
    console.log("Daily component mounted.");

    this.dailyTab = new Tab.DailyTab();
    this.dailyTab.Run();
  },

  components: {
    BudgetBar,
  },
};
</script>