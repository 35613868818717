export default {
	DailyTab() {

		var _self = this;
		var _period = new PeriodManager(false);
		var _communicator;
		var _worklogQueryAgent;
		var _accounts;

		var _lastDayTeamProtocol;
		var _preventionTimeReport;
		var _totalTimePreviewReport;
		var _internalTasksPreviewReport;
		var _logimaOverviewPreviewReport;

		var _radcalBudgetChart;
		var _radcalAccountPreviewReport;
		var _vacutecBudgetChart;
		var _vacutecAccountPreviewReport;
		var _ibaBudgetChart;
		var _ibaOverviewPreviewReport;
		var _thermoBudgetChart;
		var _thermoAccountPreviewReport;
		var _rheintachoBudgetChart;
		var _rheintachoAccountPreviewReport;

		function Init() {

			Polyfill();
			_communicator = new TempoAPICommunicator();
		}

		this.Run = function () {

			_communicator.GetWorklogsForPeriod(_period.startDate, _period.endDate, function (worklogs) {
				_worklogQueryAgent = new WorklogQueryAgent(worklogs, _accounts);
				CollectAndDisplayAll();
			});
		}

		function CollectAndDisplayAll() {

			CreateAllReportObjectsWithRecentWorklogQueryAgent();
			CollectDataForAllReports();
			_self.DrawAllReportsToDashboard();
		}

		function CreateAllReportObjectsWithRecentWorklogQueryAgent() {

			if (_lastDayTeamProtocol == null) {
				_lastDayTeamProtocol = new LastDayTeamProtocol(_worklogQueryAgent, _period);
			}
			_preventionTimeReport = new PreventionTimeReport(_worklogQueryAgent, Number(window.dashboard_config['vorsorge_monthly_budget_max']));
			_totalTimePreviewReport = new TotalTimeReport(_worklogQueryAgent);
			_internalTasksPreviewReport = new InternalTasksReport(_worklogQueryAgent);
			_logimaOverviewPreviewReport = new OverviewReport(_worklogQueryAgent, 'Logima');
			_radcalAccountPreviewReport = new AccountReport(_worklogQueryAgent, 'Radcal');
			_vacutecAccountPreviewReport = new AccountReport(_worklogQueryAgent, 'VacuTec');
			_ibaOverviewPreviewReport = new OverviewReport(_worklogQueryAgent, 'Iba');
			_radcalBudgetChart = new TimeBudgetReport(_worklogQueryAgent, 'Radcal Monthly Budget', 'Radcal', Number(window.dashboard_config['radcal_monthly_budget_max']));
			_vacutecBudgetChart = new TimeBudgetReport(_worklogQueryAgent, 'VacuTec Monthly Budget', 'VacuTec', Number(window.dashboard_config['vacutec_monthly_budget_max']));
			_ibaBudgetChart = new TimeBudgetReport(_worklogQueryAgent, 'IBA Monthly Budget', 'Iba', Number(window.dashboard_config['iba_monthly_budget_max']));
			_thermoBudgetChart = new TimeBudgetReport(_worklogQueryAgent, 'Thermo Monthly Budget', 'Thermo', Number(window.dashboard_config['thermo_monthly_budget_max']));
			_thermoAccountPreviewReport = new AccountReport(_worklogQueryAgent, 'Thermo');
			_rheintachoBudgetChart = new TimeBudgetReport(_worklogQueryAgent, 'Rheintacho Monthly Budget', 'Rheintacho', Number(window.dashboard_config['rheintacho_monthly_budget_max']));
			_rheintachoAccountPreviewReport = new AccountReport(_worklogQueryAgent, 'Rheintacho');
		}

		function CollectDataForAllReports() {

			_lastDayTeamProtocol.CollectData();
			_preventionTimeReport.CollectData();
			_totalTimePreviewReport.CollectData();
			_internalTasksPreviewReport.CollectData();
			_logimaOverviewPreviewReport.CollectData();
			_radcalAccountPreviewReport.CollectData();
			_vacutecAccountPreviewReport.CollectData();
			_ibaOverviewPreviewReport.CollectData();
			_radcalBudgetChart.CollectData();
			_vacutecBudgetChart.CollectData();
			_ibaBudgetChart.CollectData();
			_thermoBudgetChart.CollectData();
			_thermoAccountPreviewReport.CollectData();
			_rheintachoBudgetChart.CollectData();
			_rheintachoAccountPreviewReport.CollectData();
		}

		this.DrawAllReportsToDashboard = function () {

			_lastDayTeamProtocol.Draw();
			StopDailyViewLoadingAnimation();

			_totalTimePreviewReport.Draw('totaltime-preview');
			_logimaOverviewPreviewReport.Draw('logima-overview-preview');
			_internalTasksPreviewReport.Draw('internal-tasks-preview');

			_vacutecAccountPreviewReport.Draw('vacutec-accounts-preview');
			_vacutecBudgetChart.Draw();

			_radcalAccountPreviewReport.Draw('radcal-accounts-preview');
			_radcalBudgetChart.Draw();

			_ibaOverviewPreviewReport.Draw('iba-overview-preview');
			_ibaBudgetChart.Draw();

			_thermoAccountPreviewReport.Draw('thermo-accounts-preview');
			_thermoBudgetChart.Draw();

			_rheintachoAccountPreviewReport.Draw('rheintacho-accounts-preview');
			_rheintachoBudgetChart.Draw();

			_preventionTimeReport.Draw();
		}

		function StopDailyViewLoadingAnimation() {
			$("#team-reports-loading-animation").css("display", "none");
		}

		Init();
	}
}